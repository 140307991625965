<template>
  <div class="navbar-wrapper">
    <v-app-bar
      :value="isShowNav"
      fixed
      color="#c1c0b6"
      flat
      :hide-on-scroll="isMobile"
    >
      <v-toolbar-title :class="{'ml-12': !isMobile}">
        <a class="link" href="#home">Michael Eltim | ID</a>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <div v-if="!isMobile">
        <a class="link mr-5" href="#about"><span>ABOUT</span></a>
        <a class="link mr-5" href="#projects">PROJECTS</a>
        <a class="link mr-5" href="#faq">FAQ</a>
        <a class="link mr-12" href="#contact">CONTACT</a>
      </div>
      <div v-else>

        <v-btn
          color="#d6ae5d"
          text
          icon
          width="30"
          height="30"
          depressed
          @click="openMenu"
        >
          <v-icon class="icon" size="35">mdi-menu</v-icon>
        </v-btn>
      </div>
    </v-app-bar>

    <v-bottom-sheet
      v-model="sheet"
      inset
      fullscreen
    >
      <v-sheet
        class="nav-menu"
      >
        <v-btn
          class="mt-10 mb-16"
          text
          icon
          color="#3f3b37"
          width="50"
          height="50"
          @click="sheet = !sheet"
        >
          <v-icon class="icon" size="55" color="#c1c0b6">mdi-close</v-icon>
        </v-btn>
        <div class="menu-item" @click="sheet = !sheet">
          <a href="#about">
            ABOUT
          </a>
        </div>
        <div class="menu-item" @click="sheet = !sheet">
          <a href="#projects">
            PROJECTS
          </a>
        </div>
        <div class="menu-item" @click="sheet = !sheet">
          <a href="#faq">
            FAQ
          </a>
        </div>
        <div class="menu-item" @click="sheet = !sheet">
          <a href="#contact">
            CONTACT
          </a>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
export default {
  name: 'NavigationBar',
  data () { 
    return {
      sheet: false
    }
  },
  props: ['isShowNav'],
  computed: {
    isMobile() {
      return window.innerWidth < 600;
    },
  },
  methods: {
    openMenu () {
      this.sheet = true
    }
  }
}
</script>

<style>
.navbar-wrapper {
  padding-bottom: 4rem;
}

span {
  color: #3f3b37 !important;
  text-decoration: none;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

span:hover {
  color: #8f8e86;
}

.link {
  color: #3f3b37 !important;
  text-decoration: none;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.link:hover {
  color: #8f8e86;
}

.v-toolbar__content {
  border-bottom: #3f3b37 solid 0.1em;
  /* font-weight: bold; */
}

/* MOBILE */
.nav-menu {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.theme--light.v-sheet {
  color: #c1c0b6;
  background-color: #3f3b37;
}

.menu-item {
  font-size: 55px;
  font-weight: 400;
  line-height: 85px;
  width: 100%;
  padding-left: 1rem;
}

a {
  color: #c1c0b6 !important;
  text-decoration: none;
}

</style>