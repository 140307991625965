<template>
  <div class="skill-wrapper" :class="{'px-2': isMobile, 'mt-12': isMobile}">
    <div :class="{'skill-header-mobile': isMobile, 'skill-header': !isMobile, 'px-16': !isMobile}">
      <v-row>
        <v-col 
          class="py-0" 
          sm="12" 
          cols="12"
          data-aos="fade-up" 
          data-aos-delay="700"
          data-aos-duration="800"
        >
          SKILLS &
        </v-col>
        <v-col 
          class="text-right py-0"
          data-aos="fade-up" 
          data-aos-delay="1000"
          data-aos-duration="1000"
        >
          MAGIC TOOLS
        </v-col>
      </v-row>
    </div>
    <div :class="{'skill-content-mobile': isMobile, 'skill-content': !isMobile, 'pl-16': !isMobile}">
      <v-row>
        <v-col 
          class="skill-title-grid pt-0" 
          :cols="isMobile ? '12' : '6'" 
          xs="12" 
          sm="12" 
          md="6" 
          lg="6" 
          xl="6"
          data-aos="fade-up" 
          data-aos-delay="1800"
          data-aos-duration="1000"
        >
          <div :class="isMobile ? 'skill-title-mobile' : 'skill-title'">SKILLS</div>
          <div class="mt-3" :class="isMobile ? 'skill-sub-mobile' : 'skill-sub'">As a UI/UX Designer, I am efficient in both designing websites from <br v-if="!isMobile"> scratch, or helping a brand redesign.</div>
        </v-col>
        <v-col class="pt-0" :cols="isMobile ? '12' : '6'" xs="12" sm="12" md="6" lg="6" xl="6" :class="{'skill-content-bottom-border': isMobile, 'mt-8': isMobile}">
          <div 
            :class="{
              'skill-item': !isMobile, 
              'skill-item-mobile': isMobile, 
              'pl-0': isMobile, 
              'pl-4': !isMobile
            }"
            data-aos="fade-left" 
            data-aos-delay="1800"
            data-aos-duration="700"
          >
            USER RESEARCH
          </div>
          <div 
            :class="{
              'skill-item': !isMobile, 
              'skill-item-mobile': isMobile, 
              'pl-0': isMobile, 
              'pl-4': !isMobile
            }"
            data-aos="fade-left" 
            data-aos-delay="2000"
            data-aos-duration="700"
          >
            WIREFRAMING
          </div>
          <div 
            :class="{
              'skill-item': !isMobile, 
              'skill-item-mobile': isMobile, 
              'pl-0': isMobile, 
              'pl-4': !isMobile
            }"
            data-aos="fade-left" 
            data-aos-delay="2200"
            data-aos-duration="700"
          >
            PROTOTYPING
          </div>
          <div 
            :class="{
              'skill-item': !isMobile, 
              'skill-item-mobile': isMobile, 
              'pl-0': isMobile, 
              'pl-4': !isMobile
            }"
            data-aos="fade-left" 
            data-aos-delay="2400"
            data-aos-duration="700"
          >
            UI DESIGN
          </div>
          <div 
            :class="{
              'skill-item': !isMobile, 
              'skill-item-mobile': isMobile, 
              'pl-0': isMobile, 
              'pl-4': !isMobile
            }"
            data-aos="fade-left" 
            data-aos-delay="2600"
            data-aos-duration="700"
          >
            INTERACTION DESIGN
          </div>
          <div 
            :class="{
              'skill-item': !isMobile, 
              'skill-item-mobile': isMobile, 
              'pl-0': isMobile, 
              'pl-4': !isMobile
            }"
            data-aos="fade-left" 
            data-aos-delay="2800"
            data-aos-duration="700"
          >
            UX WRITING
          </div>
          <div 
            :class="{
              'skill-item': !isMobile, 
              'skill-item-mobile': isMobile, 
              'pl-0': isMobile, 
              'pl-4': !isMobile
            }"
            data-aos="fade-left" 
            data-aos-delay="3000"
            data-aos-duration="700"
          >
            USER TESTING
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col 
          class="skill-title-grid" 
          :class="isMobile ? 'mt-2' : ''"
          :cols="isMobile ? '12' : '6'" 
          xs="12" 
          sm="12" 
          md="6" 
          lg="6" 
          xl="6" 
          data-aos="fade-up"
          data-aos-delay="2000"
          data-aos-duration="1000"
        >
          <div :class="isMobile ? 'skill-title-mobile' : 'skill-title'">TOOLS</div>
          <div class="mt-3" :class="isMobile ? 'skill-sub-mobile' : 'skill-sub'">I then bring your online vision to life with coding or CMS, <br v-if="!isMobile"> depending on your needs.</div>
        </v-col>
        <v-col :class="{'tools-row': !isMobile, 'tools-row-mobile': isMobile, 'mt-8': isMobile}" :cols="isMobile ? '12' : '6'" xs="12" sm="12" md="6" lg="6" xl="6" >
          <div 
            :class="{
              'skill-item': !isMobile, 
              'skill-item-mobile': isMobile, 
              'pl-0': isMobile, 
              'pl-4': !isMobile
            }"
            data-aos="fade-left" 
            data-aos-delay="1400"
            data-aos-duration="700"
          >
            HTML
          </div>
          <div 
            :class="{
              'skill-item': !isMobile, 
              'skill-item-mobile': isMobile, 
              'pl-0': isMobile, 
              'pl-4': !isMobile
            }"
            data-aos="fade-left" 
            data-aos-delay="1600"
            data-aos-duration="700"
          >
            CSS
          </div>
          <div 
            :class="{
              'skill-item': !isMobile, 
              'skill-item-mobile': isMobile, 
              'pl-0': isMobile, 
              'pl-4': !isMobile
            }"
            data-aos="fade-left" 
            data-aos-delay="1800"
            data-aos-duration="700"
          >
            JAVASCRIPT
          </div>
          <div 
            :class="{
              'skill-item': !isMobile, 
              'skill-item-mobile': isMobile, 
              'pl-0': isMobile, 
              'pl-4': !isMobile
            }"
            data-aos="fade-left" 
            data-aos-delay="2000"
            data-aos-duration="700"
          >
            VUE JS
          </div>
          <div 
            :class="{
              'skill-item': !isMobile, 
              'skill-item-mobile': isMobile, 
              'pl-0': isMobile, 
              'pl-4': !isMobile
            }"
            data-aos="fade-left" 
            data-aos-delay="2200"
            data-aos-duration="700"
          >
            REACT JS
          </div>
          <div 
            :class="{
              'skill-item': !isMobile, 
              'skill-item-mobile': isMobile, 
              'pl-0': isMobile, 
              'pl-4': !isMobile
            }"
            data-aos="fade-left" 
            data-aos-delay="2400"
            data-aos-duration="700"
          >
            NODE JS
          </div>
          <div 
            :class="{
              'skill-item': !isMobile, 
              'skill-item-mobile': isMobile, 
              'pl-0': isMobile, 
              'pl-4': !isMobile
            }"
            data-aos="fade-left" 
            data-aos-delay="2600"
            data-aos-duration="700"
          >
            MONGOOSE
          </div>
          <div 
            :class="{
              'skill-item': !isMobile, 
              'skill-item-mobile': isMobile, 
              'pl-0': isMobile, 
              'pl-4': !isMobile
            }"
            data-aos="fade-left" 
            data-aos-delay="2800"
            data-aos-duration="700"
          >
            FIREBASE
          </div>
          <div 
            :class="{
              'skill-item': !isMobile, 
              'skill-item-mobile': isMobile, 
              'pl-0': isMobile, 
              'pl-4': !isMobile
            }"
            data-aos="fade-left" 
            data-aos-delay="3000"
            data-aos-duration="700"
          >
            FIGMA
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkillsPage',
  props: ['isMobile']
}
</script>

<style scoped>

.skill-wrapper {
  margin-bottom: 5rem;
  min-height: 100vh;
}
.skill-header {
  font-size: 11em;
  font-weight: 400;
}
.skill-content {
  margin-top: 8rem;
}
.skill-title {
  font-size: 6em;
  font-weight: 400;
}
.skill-sub {
  font-size: 1.4em;
  font-weight: 400;
}
.skill-item {
  font-size: 3.8em;
  font-weight: 400;
  line-height: 75px;
}
.skill-title-grid {
  border-right: #3f3b37 0.1em solid;
}
.tools-row {
  border-top: #3f3b37 0.1em solid;
}

/* MOBILE */

.skill-header-mobile {
  font-size: 3.4375em;
  font-weight: 400;
}

.skill-content-mobile {
  margin-top: 5rem;
}

.skill-title-mobile {
  font-size: 2.4375em;
  font-weight: 400;
}

.skill-sub-mobile {
  font-size: 1.125em;
  font-weight: 400;
}

.skill-item-mobile {
  font-size: 2.06em;
  font-weight: 400;
  line-height: 47px;
}

.tools-row-mobile {
  border: none;
}

.skill-content-bottom-border {
  border-bottom: #3f3b37 0.1em solid;
  padding-bottom: 2rem;
}

</style>