<template>
  <div id="faq" class="faq-wrapper" :class="{'px-2': isMobile, 'px-16': !isMobile, 'faq-wrapper-mobile': isMobile}">
    <div :class="isMobile ? 'faq-header-mobile' : 'faq-header'">
      <v-row>
        <v-col 
          class="py-0" 
          sm="12" 
          cols="12"
          data-aos="fade-up" 
          data-aos-delay="200"
          data-aos-duration="800"
        >
          FREQUENTLY
        </v-col>
        <v-col 
          class="text-right py-0" 
          sm="12"
          data-aos="fade-up" 
          data-aos-delay="1000"
          data-aos-duration="1000"
        >
          ASKED
        </v-col>
        <v-col 
          :offset="isMobile ? '0' : '1'" 
          class="py-0"
          data-aos="fade-up" 
          data-aos-delay="1200"
          data-aos-duration="1200"
        >
          QUESTION
        </v-col>
      </v-row>
    </div>
    <div :class="isMobile ? 'faq-content-mobile' : 'faq-content'">
      <div :class="{'faq-avatar-wrapper-mobile': isMobile}">

        <img 
          id="anchor-2"
          :class="isMobile ? 'faq-img-mobile' : 'faq-img'" 
          src="../assets/avatar-faq.png"
          alt="faq-avatar"
        >
        <div id="eyes-2" v-if="!isMobile">
          <img class="eye-2" src="../assets/eye.png" alt="" width="49rem" height="49rem" style="bottom: 254px; right: -182px;">
          <img class="eye-2" src="../assets/eye.png" alt="" width="49rem" height="49rem" style="bottom: 254px; right: -248px;">
        </div>
      </div>
      <v-expansion-panels 
        accordion 
        flat 
        tile 
        :class="{'mt-2': !isMobile, 'mt-5': isMobile}"
        data-aos="fade-left" 
        data-aos-delay="1000"
        data-aos-duration="500"
      >
        <v-expansion-panel>
          <v-expansion-panel-header 
            :class="isMobile ? 'faq-panel-header-mobile' : 'faq-panel-header'" 
            :color="'#3f3b37'"
          >
            How much is a website?
            <template v-slot:actions>
              <v-icon color="#c1c0b6">
                $expand
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content :color="'#3f3b37'" class="px-0">
            <div :class="{'faq-panel-content': !isMobile, 'faq-panel-content-mobile': isMobile, 'py-4': !isMobile}">
              <v-row>
                <v-col :offset="isMobile ? 0 : 5">
                  The cost of a website can vary depending on the scope of the project, the features you require, and other factors. For a basic website with a few pages, the cost could start at around $340.
                </v-col>
              </v-row>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header 
            :class="isMobile ? 'faq-panel-header-mobile' : 'faq-panel-header'" 
            :color="'#3f3b37'"
          >
            How long does it take to make such awesome website?
            <template v-slot:actions>
              <v-icon color="#c1c0b6">
                $expand
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content :color="'#3f3b37'">
            <div :class="{'faq-panel-content': !isMobile, 'faq-panel-content-mobile': isMobile, 'py-4': !isMobile}">
              <v-row>
                <v-col :offset="isMobile ? 0 : 5">
                  Small projects take ~2 months and larger ones 6-8 months.
                </v-col>
              </v-row>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header 
            :class="isMobile ? 'faq-panel-header-mobile' : 'faq-panel-header'" 
            :color="'#3f3b37'"
          >
            What's up with the eyes??
            <template v-slot:actions>
              <v-icon color="#c1c0b6">
                $expand
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content :color="'#3f3b37'">
            <div :class="{'faq-panel-content': !isMobile, 'faq-panel-content-mobile': isMobile, 'py-4': !isMobile}">

              <v-row>
                <v-col :offset="isMobile ? 0 : 5">
                  I wanted to build a painting that winks but it turned out to be ugly. So I improvised. See? Pure creativity.
                </v-col>
              </v-row>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header 
            :class="isMobile ? 'faq-panel-header-mobile' : 'faq-panel-header'" 
            :color="'#3f3b37'"
          >
            What makes you special?
            <template v-slot:actions>
              <v-icon color="#c1c0b6">
                $expand
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content :color="'#3f3b37'">
            <div :class="{'faq-panel-content': !isMobile, 'faq-panel-content-mobile': isMobile, 'py-4': !isMobile}">
              <v-row>
                <v-col :offset="isMobile ? 0 : 5">
                  The fact that you're scrolling this far means I've done my job pretty well designing this interface ;)
                </v-col>
              </v-row>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header 
            :class="isMobile ? 'faq-panel-header-mobile' : 'faq-panel-header'" 
            :color="'#3f3b37'"
          >
            How do you build my website?
            <template v-slot:actions>
              <v-icon color="#c1c0b6">
                $expand
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content :color="'#3f3b37'">
            <div :class="{'faq-panel-content': !isMobile, 'faq-panel-content-mobile': isMobile, 'py-4': !isMobile}">

              <v-row>
                <v-col :offset="isMobile ? 0 : 5">
                  It depends. For design we'll use Figma, Photoshop & other tools. We then build it using Webflow or custom code, depending on everyone's needs. Send me a mail if you're unsure.
                </v-col>
              </v-row>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header 
            :class="isMobile ? 'faq-panel-header-mobile' : 'faq-panel-header'" 
            :color="'#3f3b37'"
          >
            How much time did this website take?
            <template v-slot:actions>
              <v-icon color="#c1c0b6">
                $expand
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content :color="'#3f3b37'">
            <div :class="{'faq-panel-content': !isMobile, 'faq-panel-content-mobile': isMobile, 'py-4': !isMobile}">
              <v-row>
                <v-col :offset="isMobile ? 0 : 5">
                  Around 2 months.
                </v-col>
              </v-row>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQ',
  props: ['isMobile']
}
</script>

<style scoped>

#eyes-2,
#eyes-2 img {
  position: absolute;
}


.faq-wrapper {
  padding-top: 10rem;
  padding-bottom: 4rem;
  min-height: 100vh;
  background-color: #3f3b37;
  color: #c1c0b6;
}

.faq-header {
  font-size: 11em;
  font-weight: 400;
}

.faq-content {
  margin-top: -23rem;
  min-height: 100vh;
}

.faq-img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  width: 350px;
}

.v-expansion-panel {
  border-bottom: #c1c0b6 solid 0.1em;
}

.faq-panel-header {
  font-size: 1.4em;
  line-height: 44px;
  font-weight: 400;
  color: #c1c0b6;
}

.faq-panel-content {
  font-size: 1.5em;
  line-height: 33px;
  font-weight: 400;
  color: #c1c0b6;
}

/* MOBILE */

.faq-avatar-wrapper-mobile {
  /* display: flex;
  justify-content: center; */
  text-align: center;
}

.faq-wrapper-mobile {
  padding-top: 6rem;
  min-height: 100vh
}

.faq-header-mobile {
  font-size: 3.4375em;
  font-weight: 400;
}

.faq-img-mobile {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  width: 200px;
}

.faq-content-mobile {
  margin-top: 2rem;
  min-height: 100vh;
}

.faq-panel-header-mobile {
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
  color: #c1c0b6;
  padding-left: 0;
  padding-right: 0;
}

.faq-panel-content-mobile {
  font-size: 18px;
  line-height: 33px;
  font-weight: 400;
  color: #c1c0b6;
  padding-left: 0;
  padding-right: 0;
}

</style>