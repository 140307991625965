<template>
  <div id="projects" class="project-wrapper">
    <div class="container left-to-right mb-5" speed=20>
      <div class='scrolling-text'>
        <h2 class="scrolling-text-content" :class="{'scrolling-text-content-mobile': isMobile}">PROJECTS - PROJECTS - PROJECTS - PROJECTS - PROJECTS - PROJECTS - PROJECTS - PROJECTS - PROJECTS - PROJECTS -</h2>
      </div>
    </div>
    <div :class="isMobile ? 'project-content-mobile' : 'project-content'">
      <v-row>
        <v-col>
          <div :class="isMobile ? 'card-container-mobile' : 'card-container'">
            <div class="card">
              <a @click="goTo('https://www.drfleurs.com', 'clickCount1')">
                <div class="card--display">
                  <h2 class="mb-2">DR FLEURS</h2>
                  <v-parallax src="https://images.unsplash.com/photo-1519378058457-4c29a0a2efac?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8Zmxvd2Vyc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60" height="40"></v-parallax>
                </div>
                <div class="card--hover">
                  <h2>DR FLEURS</h2>
                  <p>Dr. Fleurs is a flower gifting shop based in Jakarta, offering a wide range of products to fulfill each of their customers' needs. <br> This project would become the foundation for their visual identity going forward. <br> So we provide an experience on the web with a lovely and pleasant flower gifting experience where happiness is amplified through every process.</p>
                  <p class="link" v-if="!isMobile">Click to see project</p>
                  <p class="link" v-else>Tap to see project</p>
                </div>
              </a>
              <div class="card--border"></div>
            </div>
          </div>
        </v-col>

        <v-col>
          <div :class="isMobile ? 'card-container-mobile' : 'card-container'">
            <div class="card">
              <a @click="goTo('https://royalnatural.net/', 'clickCount2')">
                <div class="card--display">
                  <h2 class="mb-2">ROYAL NATURAL</h2>
                  <v-parallax src="https://images.unsplash.com/photo-1447933601403-0c6688de566e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8Y29mZmVlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60" height="40"></v-parallax>
                </div>
                <div class="card--hover">
                  <h2>ROYAL NATURAL</h2>
                  <p>Royal Natural is a healthy food shop based in Jakarta to provide wide range of healthy foods <br> and specialty coffee beans that aims to revolutionize the way it is produced and consumed, focusing on quality rather than quantity. <br> So we design a web with natural design with translation of different languages to share the happiness and freshness of Royal Natural.</p>
                  <p class="link" v-if="!isMobile">Click to see project</p>
                  <p class="link" v-else>Tap to see project</p>
                </div>
              </a>
              <div class="card--border"></div>
            </div>
          </div>
        </v-col>

        <v-col>
          <div :class="isMobile ? 'card-container-mobile' : 'card-container'">
            <div class="card">
              <a @click="goTo('https://orangegrocer.com/', 'clickCount3')">
                <div class="card--display">
                  <h2 class="mb-2">ORANGE GROCER</h2>
                  <v-parallax src="https://images.unsplash.com/photo-1611080626919-7cf5a9dbab5b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8b3JhbmdlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60" height="40"></v-parallax>
                </div>
                <div class="card--hover">
                  <h2>ORANGE GROCER</h2>
                  <p>Orange Grocer is an online grocery shop based in Los Angeles. 
                    <br> They needed a modern, attractive design to improve their overall visuals and technology development. <br> So we reshaped their current website to turn it into something that truly represent their personality as an online grocery website.</p>
                  <p class="link" v-if="!isMobile">Click to see project</p>
                  <p class="link" v-else>Tap to see project</p>
                </div>
              </a>
              <div class="card--border"></div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectPage',
  props: ['isMobile'],
  data() {
    return {
      clickCounter: {
        clickCount1: 0,
        clickCount2: 0,
        clickCount3: 0,
      },
      prevLink: ''
    }
  },
  methods: {
    goTo(url, linkOrder) {
      if (!this.isMobile) {
        window.open(url)
      } else {
        console.log(linkOrder)
        if(this.prevLink !== linkOrder) {
          this.clickCounter[this.prevLink] = 0
        }

        if (this.clickCounter[linkOrder] === 2) {
          window.open(url)
          this.clickCounter[linkOrder] = 0
        } else if (this.clickCounter[linkOrder] < 2) {
          this.clickCounter[linkOrder]++
          this.prevLink = linkOrder
        }
      }
    }
  }
}
</script>

<style scoped>

.project-wrapper {
  /* min-height: 100vh; */
  padding-top: 3rem;
}

.container {
  width: 100vw;
  overflow: hidden;
}

.scrolling-text {
  display: inline-block;
  transition: transform 0.5s cubic-bezier(0.23, 0.36, 0.28, 0.83);
  will-change: transform;
  backface-visibility: visible;
}

.scrolling-text .scrolling-text-content {
  font-size: 11em;
  font-weight: 400;
  white-space: nowrap;
  transition: transform 0.5s cubic-bezier(0.23, 0.36, 0.28, 0.83);
  line-height: 1em;
}


.project-content {
  min-height: 100vh;
  padding-top: 5rem;
  margin: 0;
}

.project-container {
  width: 100%;
  height: 100%;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  display: flex;
}

.section-one {
  background-image: url('~@/assets/project-drFleurs.jpg');
}

.section-two {
  background-image: url('~@/assets/project-royalnatural.png');
}

.section-three {
  background-image: url('~@/assets/project-orangegrocer.png');
}

.portfolio-title {
  color: #c1c0b6;
  font-size: 142px;
  font-weight: 400;
  text-transform: uppercase;
  display: block;
  z-index: 2;
  position: relative;
}

.swipe-text {
  font-weight: 400;
  white-space: nowrap;
  transition: transform 0.5s cubic-bezier(0.23, 0.36, 0.28, 0.83);
  line-height: 1em;
  font-size: 50px;
}

.cover-wrapper {
  position: absolute;
}

.title-cover {
  --m:
    radial-gradient(circle farthest-side at right,#000 99%,#0000) 
      0 100%/46% 92% no-repeat,
    radial-gradient(circle farthest-side at left ,#000 99%,#0000) 
      100% 0/46% 92% no-repeat;
  -webkit-mask: var(--m);
          mask: var(--m);
  filter: grayscale(.5);
  transition: .3s linear;
  cursor: pointer;
  display: block;
  z-index: 1;
}

.title-cover:hover {
  -webkit-mask-position: 7.5% 50%,92.5% 50%;
          mask-position: 7.5% 50%,92.5% 50%;
  filter: grayscale(0);
}


/* MOBILE */

.scrolling-text .scrolling-text-content-mobile {
  font-size: 3.6875em;
  font-weight: 400;
  white-space: nowrap;
  transition: transform 0.5s cubic-bezier(0.23, 0.36, 0.28, 0.83);
  line-height: 1em;
}

.portfolio-title-mobile {
  color: #c1c0b6;
  font-size: 60px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
}


.swipe-text-mobile {
  font-weight: 400;
  white-space: nowrap;
  transition: transform 0.5s cubic-bezier(0.23, 0.36, 0.28, 0.83);
  line-height: 1em;
  font-size: 30px;
}


.project-content-mobile {
  min-height: 100vh;
  padding-top: 2rem;
  margin: 0;
}





.card-container {
  flex: 300px;
  margin: 30px;
}

.card-container .card {
  font-weight: bold;
  position: relative;
  width: 100%;
  z-index: 2;
}
.card-container .card a {
  padding: 20px;
  width: 100%;
  height: 35rem;
  border: 0.1em solid #3f3b37;
  background: #c1c0b6;
  text-decoration: none;
  color: #3f3b37;
  display: block;
  transition: 1s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-container .card a:hover {
  transform: translate(-20px, -20px);
  border-color: #D7AE5D;
}
.card-container .card a:hover .card--display {
  display: none;
}
.card-container .card a:hover .card--hover {
  display: block;
}
.card-container .card a .card--display h2 {
  font-size: 2em;
  /* margin: 20px 0 0; */
  color: #3f3b37;
}
.card-container .card a .card--hover {
  display: none;
}
.card-container .card a .card--hover h2 {
  transition: 1s ease;
  margin: 20px 0;
  font-size: 1.4em;
  color: #3f3b37;
}
.card-container .card a .card--hover p {
  transition: 1s ease;
  font-size: 1.2em;
  font-weight: normal;
  line-height: 1.5;
  color: #3f3b37;
}
.card-container .card a .card--hover p.link {
  margin: 30px 0 0;
  font-weight: bold;
  color: #D7AE5D;
  text-align: right;
}
.card-container .card .card--border {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border: 0.1em dashed #3f3b37;
  z-index: -1;
}
.card-container .card.card--dark a {
  color: #c1c0b6;
  background-color: #3f3b37;
  border-color: #3f3b37;
}
.card-container .card.card--dark a .card--hover .link {
  color: #D7AE5D;
}







.card-container-mobile {
  flex: 300px;
  margin: 30px 50px;
}

.card-container-mobile .card {
  font-weight: bold;
  position: relative;
  width: 100%;
  z-index: 2;
}
.card-container-mobile .card a {
  padding: 30px;
  width: 100%;
  height: 25rem;
  border: 0.13em solid #3f3b37;
  background: #c1c0b6;
  text-decoration: none;
  color: #3f3b37;
  display: block;
  transition: 1s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-container-mobile .card a:hover {
  transform: translate(-20px, -20px);
  border-color: #D7AE5D;
}
.card-container-mobile .card .card--display {
  text-align: center;
  width: 100%;
}
.card-container-mobile .card a:hover .card--display {
  display: none;
}
.card-container-mobile .card a:hover .card--hover {
  display: block;
}
.card-container-mobile .card a .card--display h2 {
  font-size: 1.8em;
  font-weight: 400;
  color: #3f3b37;
}
.card-container-mobile .card a .card--hover {
  display: none;
}
.card-container-mobile .card a .card--hover h2 {
  transition: 1s ease;
  margin: 20px 0;
  font-size: 1.4em;
  color: #3f3b37;
}
.card-container-mobile .card a .card--hover p {
  transition: 1s ease;
  font-size: .85em;
  font-weight: normal;
  line-height: 1.5;
  color: #3f3b37;
}
.card-container-mobile .card a .card--hover p.link {
  font-size: .8em;
  margin: 30px 0 0;
  font-weight: bold;
  color: #D7AE5D;
  text-align: right;
}
.card-container-mobile .card .card--border {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border: .13em dashed #3f3b37;
  z-index: -1;
}
.card-container-mobile .card.card--dark a {
  color: #c1c0b6;
  background-color: #3f3b37;
  border-color: #3f3b37;
}
.card-container-mobile .card.card--dark a .card--hover .link {
  color: #D7AE5D;
}




</style>