<template>
  <div id="contact1" class="contact-wrapper" :class="{'pt-13': isMobile, 'pt-10': !isMobile}">
    <div class="container left-to-right" speed=20>
      <div class='scrolling-text'>
        <h2 class="scrolling-text-content" :class="{'scrolling-text-content-mobile': isMobile}">CONTACT - CONTACT - CONTACT - CONTACT - CONTACT - CONTACT - CONTACT - CONTACT - CONTACT - CONTACT - CONTACT - </h2>
      </div>
    </div>
    <div :class="isMobile ? 'contact-content-mobile' : 'contact-content'">
      <div 
        class="content-text mb-5" 
        :class="{'content-text-mobile': isMobile}"
        data-aos="fade-down" 
        data-aos-delay="500"
        data-aos-duration="700"
      >
        LET'S MAKE MASTERPIECE!
      </div>
      <div class="start-btn">
        <v-btn
          color="#3f3b37"
          rounded
          :width="isMobile ? 350 : 580"
          height="70"
          depressed
          data-aos="fade-up" 
          data-aos-delay="600"
          data-aos-duration="700"
          @mouseover="onHoverStart"
          @mouseleave="onHoverLeave"
          @click="onClickStart"
        >
          <span class="start-text">
            Start a project
          </span>
        </v-btn>
      </div>
    </div>
    <div :class="isMobile ? 'contact-info-wrapper-mobile' : 'contact-info-wrapper'">
      <v-row class="contact-info-wrapper-row" :class="{'contact-info-wrapper-row-mobile': isMobile}">
        <v-col sm="4" class="avatar-image pb-0" v-if="!isMobile">
          <img class="side" :class="isOnHover ? 'up' : ''" :src="avatarContactBlink" alt="">
        </v-col>
        <v-col sm="4" class="avatar-image pb-0" v-if="!isMobile">
          <img 
            id="anchor-3"
            :src="isOnHover ? avatarContactBlink : avatarContact"
            alt="avatar-contact"
          >
          <div id="eyes-3" v-if="!isOnHover">
            <img class="eye-3" src="../assets/eye.png" alt="" width="38rem" height="38rem" style="bottom: -119px; right: 17px;">
            <img class="eye-3" src="../assets/eye.png" alt="" width="38rem" height="38rem" style="bottom: -119px; right: -34px;">
          </div>
        </v-col>
        <v-col sm="4" class="avatar-image pb-0" v-if="!isMobile">
          <img class="side" :class="isOnHover ? 'up' : ''" :src="avatarContactBlink" alt="">
        </v-col>
        <v-col sm="3" class="border-right border-top" :class="isMobile ? '' : 'side-avatar-cover'" v-if="!isMobile">
          <div class="contact-text pa-6">
            This portfolio was designed and developed on May 2023.
          </div>
        </v-col>
        <v-col sm="3" class="border-right border-top" :cols="isMobile ? 12 : 3" :class="isMobile ? 'text-center' : 'text-center'">
          <div :class="isMobile ? 'pa-0' : 'pa-6'">
            <div class="contact-info-heading">
              SOCIALS
            </div>
            <div class="contact-info-items">
              <v-btn
                :class="isMobile ? 'mt-2' : 'mt-5'"
                color="#d6ae5d"
                text
                icon
                :width="isMobile ? 45 : 60"
                :height="isMobile ? 45 : 60"
                outlined
                depressed
                @click="goTo('https://github.com/eltim12/')"
              >
                <v-icon class="icon" :size="isMobile ? 40 : 55">mdi-github</v-icon>
              </v-btn>
              <v-btn
                :class="isMobile ? 'mt-2' : 'mt-5'"
                class="ml-6"
                color="#d6ae5d"
                text
                icon
                :width="isMobile ? 45 : 60"
                :height="isMobile ? 45 : 60"
                outlined
                depressed
                @click="goTo('https://www.instagram.com/michael.eltim/')"
              >
                <v-icon class="icon" :size="isMobile ? 40 : 55">mdi-instagram</v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col sm="3" class="border-right border-top" :cols="isMobile ? 12 : 3" :class="isMobile ? 'text-center' : 'text-center'">
          <div :class="isMobile ? 'pa-0' : 'pa-6'">
            <div class="contact-info-heading">
              CONTACTS
            </div>
            <div class="contact-info-items">
              <v-btn
                color="#d6ae5d"
                :class="isMobile ? 'mt-2' : 'mt-5'"
                text
                icon
                :width="isMobile ? 45 : 60"
                :height="isMobile ? 45 : 60"
                outlined
                depressed
                @click="goTo('https://www.linkedin.com/in/eltim/')"
              >
                <v-icon class="icon" :size="isMobile ? 40 : 55">mdi-linkedin</v-icon>
              </v-btn>
              <v-btn
                class="ml-6"
                :class="isMobile ? 'mt-2' : 'mt-5'"
                color="#d6ae5d"
                text
                icon
                :width="isMobile ? 45 : 60"
                :height="isMobile ? 45 : 60"
                outlined
                depressed
                href="mailto:michael.eltim@gmail.com"
              >
                <v-icon class="icon" :size="isMobile ? 40 : 55">mdi-email-outline</v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col sm="3" class="border-top" :cols="isMobile ? 12 : 3" :class="{'side-avatar-cover': !isMobile, 'text-center': isMobile}">
          <div :class="{'contact-text': !isMobile, 'contact-text-mobile': isMobile, 'py-6': isMobile, 'pa-6': !isMobile}">
            ©2023 Michael Eltim - All Rights Reserved
          </div>
        </v-col>
        <v-col sm="12" class="border-top">
          <a href="#home">
            <div class="container left-to-right" speed=1>
              <div class='scrolling-text'>
                <h2 :class="isMobile ? 'scrolling-text-content-back-mobile' : 'scrolling-text-content-back'">
                    - BACK TO TOP - BACK TO TOP - BACK TO TOP - BACK TO TOP - BACK TO TOP - BACK TO TOP - BACK TO TOP - BACK TO TOP - BACK TO TOP -
                </h2>
              </div>
            </div>
          </a>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import avatarContact from '../assets/avatar-contact.png'
import avatarContactBlink from '../assets/avatar-contact-blink.png'

export default {
  name: 'ContactPage',
  props: ['isMobile'],
  data () {
    return {
      isOnHover: false,
      avatarContact,
      avatarContactBlink,
    }
  },
  methods: {
    goTo(url) {
      window.open(
        url
      )
    },
    onHoverStart () {
      this.isOnHover = true
    },
    onHoverLeave () {
      this.isOnHover = false
    },
    onClickStart() {
      const url = "https://api.whatsapp.com/send"
      let text = `Hello Tim! I want to consult about my website.`
      window.open(
        url + "?phone=+62818907908&text=" + encodeURIComponent(text)
      );
    },
  }
}
</script>

<style scoped>

#eyes-3,
#eyes-3 img {
  position: absolute;
}

.contact-wrapper {
  min-height: 110vh
}

.container {
  width: 100vw;
  overflow: hidden;
}

.scrolling-text {
  display: inline-block;
  transition: transform 0.5s cubic-bezier(0.23, 0.36, 0.28, 0.83);
  will-change: transform;
  backface-visibility: hidden;
}

.scrolling-text .scrolling-text-content {
  font-size: 11em;
  font-weight: 400;
  white-space: nowrap;
  transition: transform 0.5s cubic-bezier(0.23, 0.36, 0.28, 0.83);
  line-height: 1em;
}

.scrolling-text .scrolling-text-content-back {
  font-size: 53px;
  font-weight: 400;
  white-space: nowrap;
  transition: transform 0.5s cubic-bezier(0.23, 0.36, 0.28, 0.83);
  line-height: 58px;
}

.contact-content {
  min-height: 38.65vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content-text {
  font-size: 84px;
  font-weight: 400;
  line-height: 92px;
}

.start-text {
  color: #c1c0b6 !important;
  font-family: NeueMontreal, sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 17px;
}
.v-btn--rounded {
  border-radius: 100px;
}

.theme--light.v-btn.v-btn--outlined.v-btn--text {
  border-color: #3f3b37;
}

.v-btn--outlined {
  border: 1.3px solid currentColor;
}

.contact-info-heading {
  font-size: 20px;
  font-weight: 600;
  line-height: 17px;
}

.contact-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}

.icon {
  color: #3f3b37;
}

.contact-info-wrapper-row {
  height: 13rem;
}

.border-right {
  border-right: #3f3b37 solid 0.1em;
}

.border-top {
  border-top: #3f3b37 solid 0.1em;
}

.avatar-image {
  display: flex;
  justify-content: center;
}

.side {
  position: relative;
  top: 169px;
  transition: top ease 0.4s;
  z-index: 0;
}
.side:hover {
  top: 0px;
}

.up {
  top: 0px;
}

.side-avatar-cover {
  z-index: 1;
  background-color: #c1c0b6;
}

/* MOBILE */

.contact-info-wrapper-mobile {
  min-height: 44vh;
}

.contact-content-mobile {
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.scrolling-text .scrolling-text-content-mobile {
  font-size: 3.6875em;
  font-weight: 400;
  white-space: nowrap;
  transition: transform 0.5s cubic-bezier(0.23, 0.36, 0.28, 0.83);
  line-height: 1em;
}

.content-text-mobile {
  font-size: 39px;
  font-weight: 400;
  line-height: 43px;
  text-align: center;
}

.contact-info-wrapper-row-mobile {
  height: 5rem;
}

.contact-text-mobile {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}

.scrolling-text .scrolling-text-content-back-mobile {
  font-size: 29px;
  font-weight: 400;
  white-space: nowrap;
  transition: transform 0.5s cubic-bezier(0.23, 0.36, 0.28, 0.83);
  line-height: 32px;
}

a {
  color: #3f3b37 !important;
  text-decoration: none;
}
</style>