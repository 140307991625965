<template>
  <div id="home1" class="home-wrapper pt-5" :class="!isMobile ? 'px-16' : 'px-4'">
    <v-row :class="isMobile ? 'pt-1' : 'pt-0'">
      <v-col sm="12">
        <p 
          class="home-title text-left" 
          :class="{'home-title-mobile': isMobile}" 
          data-aos="fade-up" 
          data-aos-delay="100"
          data-aos-duration="500"
          >
            CREATIVE
          </p>
      </v-col>
      <v-col sm="12">
        <p 
          class="home-title text-right" 
          :class="{'home-title-mobile': isMobile}" 
          data-aos="fade-up" 
          data-aos-delay="400"
          data-aos-duration="500"
        >
          DEVELOPER
        </p>
      </v-col>
      <v-col class="pa-0" :offset="!isMobile ? 1 : 0">
        <p 
          class="home-title" 
          :class="{'home-title-mobile': isMobile, 'text-left': isMobile}" 
          data-aos="fade-up" 
          data-aos-delay="600"
          data-aos-duration="500"
        >
          & DESIGNER
        </p>
      </v-col>
    </v-row>

    <v-row 
      class="mt-12" 
      data-aos="fade-up" 
      data-aos-delay="1500"
      data-aos-duration="400"
    >
      <v-col :class="{'contact-list-mobile': isMobile}" :cols="isMobile ? 12 : 4">
        <v-btn
          color="#d6ae5d"
          text
          icon
          :width="isMobile ? 45 : 60"
          :height="isMobile ? 45 : 60"
          outlined
          depressed
          @click="goTo('https://www.linkedin.com/in/eltim/')"
        >
          <v-icon class="icon" :size="isMobile ? 40 : 55">mdi-linkedin</v-icon>
        </v-btn>
        <v-btn
          class="ml-5"
          color="#d6ae5d"
          text
          icon
          :width="isMobile ? 45 : 60"
          :height="isMobile ? 45 : 60"
          outlined
          depressed
          @click="goTo('https://github.com/eltim12/')"
        >
          <v-icon class="icon" :size="isMobile ? 40 : 55">mdi-github</v-icon>
        </v-btn>
        <v-btn
          class="ml-5"
          color="#d6ae5d"
          text
          icon
          :width="isMobile ? 45 : 60"
          :height="isMobile ? 45 : 60"
          outlined
          depressed
          href="mailto:michael.eltim@gmail.com"
        >
          <v-icon class="icon" :size="isMobile ? 40 : 55">mdi-email-outline</v-icon>
        </v-btn>
        <v-btn
          class="ml-5"
          color="#d6ae5d"
          text
          icon
          :width="isMobile ? 45 : 60"
          :height="isMobile ? 45 : 60"
          outlined
          depressed
          @click="goTo('https://www.instagram.com/michael.eltim/')"
        >
          <v-icon class="icon" :size="isMobile ? 40 : 55">mdi-instagram</v-icon>
        </v-btn>
      </v-col>
      <v-col :cols="isMobile ? 12 : 4">
        <div class="avatar-wrapper">
          <img
            id="anchor"
            :class="{'avatar-img-mobile': isMobile, 'avatar-img': !isMobile}" 
            src="../assets/avatar-home.png"
            alt="home-avatar"
          >
          <div id="eyes" v-if="!isMobile">
            <img class="eye" src="../assets/eye-blink.png" alt="" width="30rem" height="30rem" style="bottom: -162px; right: 38px;">
            <img class="eye" src="../assets/eye-blink.png" alt="" width="30rem" height="30rem" style="bottom: -162px; right: -5px;">
          </div>
        </div>
      </v-col>
      <v-col class="text-right" :cols="isMobile ? 12 : 4">
        <v-btn
          color="#3f3b37"
          rounded
          width="350"
          height="70"
          depressed
          @click="onClickStart"
        >
          <span class="start-text">
            Start a project
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  props: ['isMobile'],
  methods: {
    goTo(url) {
      window.open(
        url
      )
    },
    onClickStart() {
      const url = "https://api.whatsapp.com/send"
      let text = `Hello Tim! I want to consult about my website.`
      window.open(
        url + "?phone=+62818907908&text=" + encodeURIComponent(text)
      );
    },
  }
}
</script>

<style scoped>

#eyes,
#eyes img {
  position: absolute;
}

.home-wrapper {
  height: 100vh;
  width: 100vw;
}

.home-title {
  font-size: 11em;
  text-transform: uppercase;
  font-weight: 400;
  margin: 0 !important;
}
.col-sm-12 {
  padding: 0;
}
.avatar-wrapper {
  display: flex;
  justify-content: center;
}
.avatar-img {
  width: 19em;
}
.icon {
  color: #3f3b37;
}
.start-text {
  color: #c1c0b6 !important;
  font-family: NeueMontreal, sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.3em;
}
.v-btn--rounded {
  border-radius: 100px;
}
.theme--light.v-btn.v-btn--outlined.v-btn--text {
  border-color: #3f3b37;
}
.v-btn--outlined {
  border: 1.3px solid currentColor;
}

/* MOBILE */

.home-title-mobile {
  font-size: 3.4375em;
}

.avatar-img-mobile {
  margin: 1rem 0;
  width: 10em;
  /* width: 20em; */
}

.contact-list-mobile {
  display: flex;
  justify-content: center;
  padding-bottom: 0;
}
</style>