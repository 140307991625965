<template>
  <v-app>
    <v-main id="home">
      <NavigationBar :isShowNav="isShowNav" :isMobile="isMobile" />
      <HomePage :isMobile="isMobile" />
      <AboutPage :isMobile="isMobile" />
      <SkillPage :isMobile="isMobile" />
      <ProjectPage @hideNav="hideNav" @showNav="showNav" :isMobile="isMobile" />
      <FAQ :isMobile="isMobile" />
      <Contact id="contact" :isMobile="isMobile" />
    </v-main>
  </v-app>
</template>

<script>
import NavigationBar from './components/Navbar';
import HomePage from './components/Home.vue'
import AboutPage from './components/About.vue'
import SkillPage from './components/Skills.vue'
import ProjectPage from './components/Project.vue'
import FAQ from './components/FAQ.vue'
import Contact from './components/Contact.vue'

export default {
  name: 'App',
  components: {
    NavigationBar,
    HomePage,
    AboutPage,
    SkillPage,
    ProjectPage,
    FAQ,
    Contact
  },
  data() {
    return {
      isShowNav: true
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth < 600;
    },
  },
  created () {
    if (this.isMobile) {
      localStorage.setItem('isMobile', true)
    } else {
      localStorage.setItem('isMobile', false)
    }
  },
  methods: {
    hideNav () {
      this.isShowNav = false
    },
    showNav () {
      this.isShowNav = true
    }
  }
};
</script>

<style>

@font-face {
  font-family: "NeueMontreal";
  src: local("NeueMontreal"),
   url(./assets/NeueMontreal-Regular.otf) format("truetype");
}

#app {
  margin: 0;
  padding: 0;
  background-color: #c1c0b6;
  font-family: NeueMontreal, sans-serif;
  color: #3f3b37;
  line-height: 1;
  overflow: hidden;
  /* cursor: none; */
}
</style>
