var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"skill-wrapper",class:{'px-2': _vm.isMobile, 'mt-12': _vm.isMobile}},[_c('div',{class:{'skill-header-mobile': _vm.isMobile, 'skill-header': !_vm.isMobile, 'px-16': !_vm.isMobile}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"sm":"12","cols":"12","data-aos":"fade-up","data-aos-delay":"700","data-aos-duration":"800"}},[_vm._v(" SKILLS & ")]),_c('v-col',{staticClass:"text-right py-0",attrs:{"data-aos":"fade-up","data-aos-delay":"1000","data-aos-duration":"1000"}},[_vm._v(" MAGIC TOOLS ")])],1)],1),_c('div',{class:{'skill-content-mobile': _vm.isMobile, 'skill-content': !_vm.isMobile, 'pl-16': !_vm.isMobile}},[_c('v-row',[_c('v-col',{staticClass:"skill-title-grid pt-0",attrs:{"cols":_vm.isMobile ? '12' : '6',"xs":"12","sm":"12","md":"6","lg":"6","xl":"6","data-aos":"fade-up","data-aos-delay":"1800","data-aos-duration":"1000"}},[_c('div',{class:_vm.isMobile ? 'skill-title-mobile' : 'skill-title'},[_vm._v("SKILLS")]),_c('div',{staticClass:"mt-3",class:_vm.isMobile ? 'skill-sub-mobile' : 'skill-sub'},[_vm._v("As a UI/UX Designer, I am efficient in both designing websites from "),(!_vm.isMobile)?_c('br'):_vm._e(),_vm._v(" scratch, or helping a brand redesign.")])]),_c('v-col',{staticClass:"pt-0",class:{'skill-content-bottom-border': _vm.isMobile, 'mt-8': _vm.isMobile},attrs:{"cols":_vm.isMobile ? '12' : '6',"xs":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('div',{class:{
            'skill-item': !_vm.isMobile, 
            'skill-item-mobile': _vm.isMobile, 
            'pl-0': _vm.isMobile, 
            'pl-4': !_vm.isMobile
          },attrs:{"data-aos":"fade-left","data-aos-delay":"1800","data-aos-duration":"700"}},[_vm._v(" USER RESEARCH ")]),_c('div',{class:{
            'skill-item': !_vm.isMobile, 
            'skill-item-mobile': _vm.isMobile, 
            'pl-0': _vm.isMobile, 
            'pl-4': !_vm.isMobile
          },attrs:{"data-aos":"fade-left","data-aos-delay":"2000","data-aos-duration":"700"}},[_vm._v(" WIREFRAMING ")]),_c('div',{class:{
            'skill-item': !_vm.isMobile, 
            'skill-item-mobile': _vm.isMobile, 
            'pl-0': _vm.isMobile, 
            'pl-4': !_vm.isMobile
          },attrs:{"data-aos":"fade-left","data-aos-delay":"2200","data-aos-duration":"700"}},[_vm._v(" PROTOTYPING ")]),_c('div',{class:{
            'skill-item': !_vm.isMobile, 
            'skill-item-mobile': _vm.isMobile, 
            'pl-0': _vm.isMobile, 
            'pl-4': !_vm.isMobile
          },attrs:{"data-aos":"fade-left","data-aos-delay":"2400","data-aos-duration":"700"}},[_vm._v(" UI DESIGN ")]),_c('div',{class:{
            'skill-item': !_vm.isMobile, 
            'skill-item-mobile': _vm.isMobile, 
            'pl-0': _vm.isMobile, 
            'pl-4': !_vm.isMobile
          },attrs:{"data-aos":"fade-left","data-aos-delay":"2600","data-aos-duration":"700"}},[_vm._v(" INTERACTION DESIGN ")]),_c('div',{class:{
            'skill-item': !_vm.isMobile, 
            'skill-item-mobile': _vm.isMobile, 
            'pl-0': _vm.isMobile, 
            'pl-4': !_vm.isMobile
          },attrs:{"data-aos":"fade-left","data-aos-delay":"2800","data-aos-duration":"700"}},[_vm._v(" UX WRITING ")]),_c('div',{class:{
            'skill-item': !_vm.isMobile, 
            'skill-item-mobile': _vm.isMobile, 
            'pl-0': _vm.isMobile, 
            'pl-4': !_vm.isMobile
          },attrs:{"data-aos":"fade-left","data-aos-delay":"3000","data-aos-duration":"700"}},[_vm._v(" USER TESTING ")])])],1),_c('v-row',[_c('v-col',{staticClass:"skill-title-grid",class:_vm.isMobile ? 'mt-2' : '',attrs:{"cols":_vm.isMobile ? '12' : '6',"xs":"12","sm":"12","md":"6","lg":"6","xl":"6","data-aos":"fade-up","data-aos-delay":"2000","data-aos-duration":"1000"}},[_c('div',{class:_vm.isMobile ? 'skill-title-mobile' : 'skill-title'},[_vm._v("TOOLS")]),_c('div',{staticClass:"mt-3",class:_vm.isMobile ? 'skill-sub-mobile' : 'skill-sub'},[_vm._v("I then bring your online vision to life with coding or CMS, "),(!_vm.isMobile)?_c('br'):_vm._e(),_vm._v(" depending on your needs.")])]),_c('v-col',{class:{'tools-row': !_vm.isMobile, 'tools-row-mobile': _vm.isMobile, 'mt-8': _vm.isMobile},attrs:{"cols":_vm.isMobile ? '12' : '6',"xs":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('div',{class:{
            'skill-item': !_vm.isMobile, 
            'skill-item-mobile': _vm.isMobile, 
            'pl-0': _vm.isMobile, 
            'pl-4': !_vm.isMobile
          },attrs:{"data-aos":"fade-left","data-aos-delay":"1400","data-aos-duration":"700"}},[_vm._v(" HTML ")]),_c('div',{class:{
            'skill-item': !_vm.isMobile, 
            'skill-item-mobile': _vm.isMobile, 
            'pl-0': _vm.isMobile, 
            'pl-4': !_vm.isMobile
          },attrs:{"data-aos":"fade-left","data-aos-delay":"1600","data-aos-duration":"700"}},[_vm._v(" CSS ")]),_c('div',{class:{
            'skill-item': !_vm.isMobile, 
            'skill-item-mobile': _vm.isMobile, 
            'pl-0': _vm.isMobile, 
            'pl-4': !_vm.isMobile
          },attrs:{"data-aos":"fade-left","data-aos-delay":"1800","data-aos-duration":"700"}},[_vm._v(" JAVASCRIPT ")]),_c('div',{class:{
            'skill-item': !_vm.isMobile, 
            'skill-item-mobile': _vm.isMobile, 
            'pl-0': _vm.isMobile, 
            'pl-4': !_vm.isMobile
          },attrs:{"data-aos":"fade-left","data-aos-delay":"2000","data-aos-duration":"700"}},[_vm._v(" VUE JS ")]),_c('div',{class:{
            'skill-item': !_vm.isMobile, 
            'skill-item-mobile': _vm.isMobile, 
            'pl-0': _vm.isMobile, 
            'pl-4': !_vm.isMobile
          },attrs:{"data-aos":"fade-left","data-aos-delay":"2200","data-aos-duration":"700"}},[_vm._v(" REACT JS ")]),_c('div',{class:{
            'skill-item': !_vm.isMobile, 
            'skill-item-mobile': _vm.isMobile, 
            'pl-0': _vm.isMobile, 
            'pl-4': !_vm.isMobile
          },attrs:{"data-aos":"fade-left","data-aos-delay":"2400","data-aos-duration":"700"}},[_vm._v(" NODE JS ")]),_c('div',{class:{
            'skill-item': !_vm.isMobile, 
            'skill-item-mobile': _vm.isMobile, 
            'pl-0': _vm.isMobile, 
            'pl-4': !_vm.isMobile
          },attrs:{"data-aos":"fade-left","data-aos-delay":"2600","data-aos-duration":"700"}},[_vm._v(" MONGOOSE ")]),_c('div',{class:{
            'skill-item': !_vm.isMobile, 
            'skill-item-mobile': _vm.isMobile, 
            'pl-0': _vm.isMobile, 
            'pl-4': !_vm.isMobile
          },attrs:{"data-aos":"fade-left","data-aos-delay":"2800","data-aos-duration":"700"}},[_vm._v(" FIREBASE ")]),_c('div',{class:{
            'skill-item': !_vm.isMobile, 
            'skill-item-mobile': _vm.isMobile, 
            'pl-0': _vm.isMobile, 
            'pl-4': !_vm.isMobile
          },attrs:{"data-aos":"fade-left","data-aos-delay":"3000","data-aos-duration":"700"}},[_vm._v(" FIGMA ")])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }