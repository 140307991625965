<template>
  <div id="about" class="about-page" :class="{'about-page-mobile': isMobile}">
    <div class="container left-to-right" speed=20>
      <div class='scrolling-text'>
        <h2 class="scrolling-text-content" :class="{'scrolling-text-content-mobile': isMobile}"> - ABOUT - ABOUT - ABOUT - ABOUT - ABOUT - ABOUT - ABOUT - ABOUT - ABOUT - ABOUT - ABOUT - ABOUT - ABOUT - ABOUT - ABOUT -</h2>
      </div>
    </div>
    <v-row class="about-content" :class="{'px-2': isMobile, 'px-16': !isMobile,'about-content-mobile': isMobile}">
      <v-col 
        sm="12" 
        cols="12" 
        class="profile-title" 
        :class="{'profile-title-mobile': isMobile}"
        data-aos="fade-up" 
        data-aos-delay="500"
        data-aos-duration="500"  
      >
        <span>PRO</span>
        <span><i>F </i></span>
        <span>ILE</span>
      </v-col>
      <v-col sm="12" cols="12">
        <v-row>
          <v-col 
            sm="6" 
            :cols="isMobile ? '12' : '6'"
            data-aos="fade-right" 
            data-aos-delay="500"
            data-aos-duration="800"  
          >
            <p class="subtitle" :class="{'subtitle-mobile': isMobile}">
              "A small intro"
            </p>
            <p class="subtitle-content" :class="{'subtitle-content-mobile': isMobile}">
              HEY, I'M A FULL-STACK DEVELOPER AND DESIGNER. <br> 
              I help businesses leave a lasting impression <br>
              in the digital world <br>
              with a touch of creativity and empathy.
            </p>
          </v-col>
          <v-col 
            sm="6" 
            class="profile-content" 
            :class="{'profile-content-mobile': isMobile}"
            data-aos="fade-left" 
            data-aos-delay="500"
            data-aos-duration="800"  
          >
            Innovative Full stack Developer with a passion to learn new skills and technologies. I have been coding since 2019, with experience in blending the art of design with skill of programming to deliver an immersive and engaging user experience through efficient website development and proactive feature optimization. <br> Aspiring to combine broad background with strong technical skills to excel as a Full-Stack Developer. I specialize in crafting modern websites that offer user-centric experiences.
          </v-col>
        </v-row>
      </v-col>
      <!-- <v-col sm="12" cols="12">
        <img src="../assets/DASHES_ANI_white_crop.gif" alt="">
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'AboutPage',
  props: ['isMobile']
}
</script>

<style scoped>

html {
  overflow: hidden;
}

.about-page {
  padding-top: 3rem;
  height: 100vh;
}

.container {
  width: 100vw;
  overflow: hidden;
}

.scrolling-text {
  display: inline-block;
  transition: transform 0.5s cubic-bezier(0.23, 0.36, 0.28, 0.83);
  will-change: transform;
  backface-visibility: hidden;
}

.scrolling-text .scrolling-text-content {
  font-size: 11em;
  font-weight: 400;
  white-space: nowrap;
  transition: transform 0.5s cubic-bezier(0.23, 0.36, 0.28, 0.83);
  line-height: 1em;
}

.about-content {
  margin-top: 15rem;
}

.profile-title {
  font-size: 6em;
  text-transform: uppercase;
  font-weight: 400;
}

.subtitle {
  font-size: 18px;
  font-weight: 800;
}
.subtitle-content {
  font-size: 15px;
  font-weight: 200;
  line-height: 26px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.profile-content {
  font-size: 23px;
  line-height: 31px;
  font-weight: 500;
}

/* MOBILE */

.scrolling-text .scrolling-text-content-mobile {
  font-size: 3.6875em;
  font-weight: 400;
  white-space: nowrap;
  transition: transform 0.5s cubic-bezier(0.23, 0.36, 0.28, 0.83);
  line-height: 1em;
}

.profile-title-mobile {
  font-size: 2.4375em;
  text-transform: uppercase;
  font-weight: 400;
}

.subtitle-mobile {
  font-size: 18px;
  font-weight: 800;
}
.subtitle-content-mobile {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: .9px;
  text-transform: uppercase;
}

.profile-content-mobile {
  font-size: 18px;
  line-height: 31px;
  font-weight: 500;
}

.about-page-mobile {
  margin-top: 0;
}

.about-content-mobile {
  margin-top: 5rem;
}

</style>